<template>
  <div class="c-app">
    <TheSidebar />
    <div class="c-wrapper">
      <TheHeader />
      <div class="c-body" id="body">
        <main class="c-main">
          <CContainer fluid>
            <CSpinner
              color="primary"
              style="width: 4rem; height: 4rem"
              v-if="loading"
            />

            <AlertBox />
            <ConfirmModal />

            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </div>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";
import { mapState } from "vuex";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },
  computed: mapState(["alert", "loading"]),
  watch: {
    alert(alertMessage) {
      console.log(alertMessage);
      if (alertMessage.message) {
        this.alertMessage = alertMessage.message;
        this.alertType = alertMessage.type;
        this.alertShow = true;
        document.getElementById("body").scrollTo(0, 0);
      } else {
        this.alertMessage = "";
        this.alertType = "";
        this.alertShow = false;
      }
    },
  },
  data() {
    return {
      alertType: "",
      alertMessage: "",
      alertShow: false,
    };
  },
  created: function () {},
  methods: {},
};
</script>

<style scoped>
.spinner-border {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -30px;
  z-index: 10;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

::v-deep .vdatetime-calendar__month {
  min-height: 312px;
}

::v-deep img {
  border-radius: 4px !important;
}
</style>
